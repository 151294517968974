<template>
    <v-container>
      <v-dialog v-if="rejectDialog" v-model="rejectDialog" persistent max-width="500px">
        <v-form
            ref="formReject"
            v-model="validReject"
            lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">Are you sure you want to {{rejectMode!=='reject'?"cancel":"reject"}} this booking? </span>
          </v-card-title>
          <v-card-text>
            <v-textarea
                id="reason"
              class="margin-top-default"
              v-model="reason"
              label="Reason of the cancellation"
              hint="Please describe the reason"
              placeholder="Please describe the reason"
              :rules="[v => !!v && v.length !== 0 || 'You must enter a reason !',v => !!v && v.length >= 25 || 'Min 25 characters']"
              required
                :maxlength="255"
            >
            </v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="btn_no" outlined @click="rejectDialog=false" class="margin-left-right-default">No</v-btn>
            <v-btn id="btn_yes" outlined @click="validateReject()!=true?null:rejectAction()" :disabled="!validReject" color="primary" class="margin-right-default">Yes</v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-dialog>
      <h2>Bookings</h2>

      <v-row class="no-margin-top-bottom" style="align-items: baseline">
        <v-col>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
              @keydown.enter="searchBookings"
              class="no-margin-top-bottom"
              dense
          ></v-text-field>
        </v-col>
        <v-col>
        <v-select v-if="$store.state.bookingStatus"
                  v-model="status"
                  :items="$store.state.bookingStatus"
                  item-text="displayName"
                  :return-object=true
                  label="Status"
                  class="no-margin-top-bottom"
                  dense
                  clearable
        ></v-select>
        </v-col>
        <v-btn align="center" outlined @click="searchBookings" class="margin-left-right-default">Search</v-btn>
      </v-row>

        <v-data-table
          v-model="selected"
                    :multi-sort="true"
                    :loading="loading"
                    :headers="headers"
                    :items="bookings"
                    class="elevation-1 pointer"
                    :hide-default-footer=false
                    :expanded="expanded"
                    item-key="id"
                    :show-expand="false"
                    :server-items-length="totalItems"
                    :options.sync="options"
                    :footer-props="{'items-per-page-options': [1,2,3,10, 20, 30, 40, 50]}"
                    @click:row="openBooking"
        >
          <template v-slot:item.status.displayName="{ item }">
            <v-chip
                @click:row="openBooking"
                class="pointer"
                :color="getColor(item.status)"
            >
              {{ item.status.displayName }}
            </v-chip>
          </template>
          <template v-slot:item.totalWithCommission="{ item }">
            {{ roundToXDigits(item.totalWithCommission,2) }}
          </template>
          <template v-slot:item.total="{ item }">
            {{ roundToXDigits(item.total,2) }}
          </template>
          <template v-slot:item.actions="{ item }" style="width: 1px; min-width: 1px;">
              <v-col style="padding:0px" >
                  <v-btn icon>
                      <v-icon small @click.stop="openBooking(item)">
                          mdi-eye-outline
                      </v-icon>
                  </v-btn>
                <v-btn icon v-if="isAdmin">
                  <v-icon small @click.stop="deleteBooking(item)">
                    mdi-delete-outline
                  </v-icon>
                </v-btn>
              </v-col>
          </template>
<!--            <template v-slot:expanded-item="{ headers, item }" >-->
<!--                <td style="white-space: pre-wrap" class=" v-data-table__mobile-row caption">{{ item.details }}</td>-->
<!--                <td :colspan="headers.length-1" style="white-space: pre-wrap" class="v-data-table__mobile-row caption">{{item.details2}}</td>-->
<!--            </template>-->
        </v-data-table>
        <div class="text--secondary">Click on a row to see the details of the booking</div>

      <div class="margin-top-default booking">
      <v-row class="margin-top-bottom" v-if="booking && (booking.status.name==='REQUESTED' || booking.status.name==='DATE_REQUESTED')  && !$router.currentRoute.path.includes('/account')">
        <v-btn id="btn_accept" align="center" color="primary" class="col margin-left-right-default" @click="acceptBooking()" :loading="loading">Accept</v-btn>
        <v-btn id="btn_reject" align="center" color="error" class="col margin-left-right-default" @click="rejectDialog=true;rejectMode='reject';rejectAction=rejectBooking" :loading="loading">Reject (Auto in {{ hours }}:{{ minutes }}:{{ seconds }}) </v-btn>
      </v-row>

        <v-progress-linear v-if="isLoadingBooking"
                           indeterminate
                           height="6"
        ></v-progress-linear>
      <v-container v-if="booking && !isLoadingBooking" id="booking_details" >
        <v-row>
          <div class="col-xs-12 col-md-7">
            <v-card >
              <div class="">
                <div style="text-align: center;font-size: medium;font-weight: 700;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                  Your Booking with {{ (isAdmin || isChef)?booking.clientName:booking.chefProfileName}}</div>
                <v-col>
                  <div style="display:flex" id="booking_reference">
                    <v-text-field
                        hide-details
                        dense
                        :value="booking.reference"
                        label="Reference"
                        prepend-icon="mdi-receipt"
                        readonly
                    ></v-text-field>

<!--                    <div v-if="$router.currentRoute.path.includes('/admin')">-->
<!--                      <v-select v-if="$store.state.bookingStatus"-->
<!--                                v-model="booking.status"-->
<!--                                :items="$store.state.bookingStatus"-->
<!--                                item-text="displayName"-->
<!--                                :return-object=true-->
<!--                                label="Status"-->
<!--                                dense-->
<!--                                clearable-->
<!--                      ></v-select>-->
<!--                    </div>-->
<!--                    <div v-else>-->
                      <v-chip id="booking_status"
                          :color="getColor(booking.status)"
                      >
                        {{ booking.status.displayName }}
                      </v-chip>
<!--                    </div>-->
                  </div>
                </v-col>
                <v-col>
                  <v-text-field
                      hide-details
                      dense
                      :value="booking.clientName"
                      label="Name"
                      prepend-icon="mdi-account"
                      readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      readonly
                      hide-details
                      dense
                      :value="booking.clientPhone"
                      label="Phone"
                      prepend-icon="mdi-phone"
                      :rules="[v => !!v || 'Phone is required']"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete
                      :no-filter="true"
                      readonly
                      :hide-details="(!isChef && !isAdmin)"
                      v-model="booking.location"
                      :items="locationItems"
                      :loading="loadingAddress"
                      :search-input.sync="lookupAddress"
                      item-text="place_name"
                      label="Location"
                      placeholder="Start typing your address"
                      prepend-icon="mdi-map-marker"
                      append-icon=""
                      return-object
                      @change="getLocation"
                      auto-select-first
                      hide-no-data
                      dense
                      :messages="distanceMessage"
                  ></v-autocomplete>
<!--                  :rules="[v => !!v || 'Location is required', v => this.checkRange()[0] || 'Chef out of range by '+this.checkRange()[1]+' Km']"-->
                </v-col>
                <v-col>
                  <v-text-field
                      hide-details
                      dense
                      v-model="booking.date"
                      label="Date"
                      prepend-icon="mdi-calendar-range"
                      readonly
                      :rules="[v => !!v || 'Date is required']"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      readonly
                      hide-details
                      dense
                      prepend-icon="mdi-clock"
                      :items="availableTimes"
                      label="Time"
                      v-model="booking.time"
                      :rules="[v => !!v || 'Time is required']"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      readonly
                      hide-details
                      dense
                      prepend-icon="mdi-account-multiple"
                      label="Guests"
                      type="number"
                      v-model.number="booking.nbGuests"
                      :rules="[v => !!v || 'Number of guests is required', v => (booking.serviceSerialized.minGuests===undefined
                                        || v>=booking.serviceSerialized.minGuests)
                                        || 'Minimum required is '+booking.serviceSerialized.minGuests]"
                  ></v-text-field>
                </v-col>
<!--                <v-col v-if="showNbKid">-->
<!--                  <v-text-field-->
<!--                      hide-details-->
<!--                      dense-->
<!--                      prepend-icon="mdi-account-multiple"-->
<!--                      label="Kids"-->
<!--                      type="number"-->
<!--                      v-model.number="booking.nbKids"-->
<!--                      :rules="[v => !!v || 'Number of guests is required']"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
                <v-col>
                  <v-textarea
                      hide-details
                      readonly
                      prepend-icon="mdi-comment"
                      dense
                      v-model="booking.comment"
                      label="Comment"
                      hint="Tell the chef any allergy requirement or any additional information"
                      placeholder="Tell the chef any allergy requirement or any additional information"
                  >
                  </v-textarea>
                </v-col>
<!--                <v-col align="center">-->
<!--                  <v-btn outlined color="primary" class="margin-default" :disabled="!valid"  @click="modify()" :loading="loadingModify">Modify</v-btn>-->
<!--                </v-col>-->

              </div>
            </v-card>

            <v-divider style="margin-top: 12px;margin-bottom: 12px;"></v-divider>

            <v-card > <!-- Location Details-->
              <div style="text-align: center;font-size: medium;font-weight: 700;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                Location Details</div>
              <v-col >
                <div style="font-size: 12px" class="primary--text">Kitchen Equipments (Please select all the Kitchen Equipments available)</div>
                <v-input disabled readonly v-model="booking.address.addressDetails.equipments" :rules="[v => v.length>0 || 'This field is required!' ,this.validateEquipmentsKitchen()===undefined || this.validateEquipmentsKitchen()]">
                  <v-item-group multiple v-model="booking.address.addressDetails.equipments">
                    <v-item
                        v-for="tag in equipmentsKitchen"
                        :key="tag.name"
                        v-slot:default="{ active, toggle }"
                        :value="tag"
                        color="primary"
                    >
                      <v-chip
                          active-class="primary--text"
                          :input-value="checkChip(booking.address.addressDetails.equipments,tag)"
                          filter
                          @click="!toggle"
                      >
                        {{ tag.displayName }}
                      </v-chip>
                    </v-item>
                  </v-item-group>
                </v-input>
              </v-col>
              <v-row class="container">
                <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
                  <div style="font-size: 12px" class="primary--text">Cooktop Type (Please select the cooktop type available)</div>
                  <v-input readonly v-model="booking.address.addressDetails.cooktopType" :rules="[v => !!v || 'This field is required!']" >
                    <v-item-group v-model="booking.address.addressDetails.cooktopType" readonly>
                      <v-item
                          v-for="tag in hobType"
                          :key="tag.name"
                          v-slot:default="{ active, toggle }"
                          :value="tag"
                          color="primary"
                      >
                        <v-chip
                            active-class="primary--text"
                            :input-value="booking.address.addressDetails.cooktopType !==undefined && tag.name ===booking.address.addressDetails.cooktopType.name"
                            filter
                            @click="!toggle"
                        >
                          {{ tag.displayName }}
                        </v-chip>
                      </v-item>
                    </v-item-group>
                  </v-input>
                </v-col>
              </v-row>
              <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
                <v-radio-group
                    readonly
                    color="primary--text"
                    v-model="booking.hostHouse"
                    row
                    label="Is the event at your home ?"
                    :rules="[v => v!==undefined  || 'This field is required!']"
                >
                  <v-radio
                      label="Yes"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      label="No"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" v-if="!booking.hostHouse">
                <v-select
                    readonly
                    v-model="booking.address.addressDetails.locationType"
                    :items="$store.state.locationType"
                    item-text="displayName"
                    item-value="name"
                    attach
                    chips
                    label="Location type"
                    color="#00695C"
                    :rules="[v => !!v && v.length !== 0 || 'You must select one location type!']"
                    required
                    return-object
                ></v-select>
              </v-col>
              <v-row class="container">
                <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
                  <v-radio-group
                      readonly
                      color="primary--text"
                      v-model="booking.haveAllServiceEquipments"
                      row
                      label="Do you have the following equipments ?"
                      :rules="[v => v!==undefined  || 'This field is required!']"
                  >
                    <v-radio
                        label="Yes"
                        :value="true"
                    ></v-radio>
                    <v-radio
                        label="No"
                        :value="false"
                    ></v-radio>
                  </v-radio-group>
                  <ul class="margin-left-default" v-for="(value,keyService) in getServiceCutlery.equipmentsService" :key="keyService">
                    <li v-if="equipmentsService.find(e => e.name===keyService)!==undefined">
                      {{value}} {{equipmentsService.find(e => e.name===keyService).displayName}}
                    </li>
                  </ul>
                </v-col>
                <v-row class="container">
                <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
                  <v-radio-group
                      readonly
                      color="primary--text"
                      v-model="booking.hasRequirements"
                      row
                      label="Do you meet the requirements ?"
                      :rules="[v => v!==undefined  || 'This field is required!']"
                  >
                    <v-radio
                        label="Yes"
                        :value="true"
                    ></v-radio>
                    <v-radio
                        label="No"
                        :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
                  {{booking.requirements}}
                </v-col>
                </v-row>
              </v-row>

            </v-card>

            <v-divider style="margin-top: 12px;margin-bottom: 12px;"></v-divider>

            <v-card > <!-- Service Description -->
              <div style="text-align: center;font-size: medium;font-weight: 700;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                Service Description </div>
              <v-col>
                <div>The chef brings all ingredients, prepares the meal in your kitchen, serves each dish, and does the washing up before leaving</div>
                <div v-if="getServiceCutlery.description!==undefined">{{getServiceCutlery.description}}</div>
                <div class="warning--text" v-if="getServiceCutlery.warning!==undefined">{{getServiceCutlery.warning}}</div>
              </v-col>
            </v-card>
          </div>

          <!-- Booking-->
          <div class="col-xs-12 col-md-5">
            <!-- Cart -->
            <v-card dark>
                <div class="row" style="width:100%;margin: 0px">
                  <v-col class="">
                    <h2>Price Details</h2>
                  </v-col>
                  <v-col v-if="$router.currentRoute.path.includes('/dashboard')" class="col-3 caption" align-self="center" style="text-align: end;max-width: unset;">
                    Net $
                  </v-col>
                  <v-col class="col-3 caption" align-self="center" style="text-align: end;max-width: unset">
                    Total $
                  </v-col>
                </div>
              <div class="background-black-light">
                <div v-for="(item,indexItem) in filteredItems" :key="indexItem">
                  <div v-if="item.itemType.name!=='MINIMUM_REMAINING'">

                    <div class="row" style="width:100%;margin: 0px">
                      <v-col v-if="$router.currentRoute.path.includes('/account')">{{getCategory(item)}} {{item.description}} {{item.unitPriceWithComm!=undefined?"($"+roundToXDigits(item.unitPriceWithComm,2)+")":""}} x {{item.qty}}</v-col>
                      <v-col v-else>{{getCategory(item)}} {{item.description}} x {{item.qty}} </v-col>
                      <v-col v-if="$router.currentRoute.path.includes('/dashboard')" class="col-3" align-self="center" style="text-align: end;max-width: unset;">
                        {{(item.unitPrice*item.qty > 0 )?formatPrice(roundToXDigits(item.unitPrice*item.qty,2)):""}}
                      </v-col>
                      <v-col class="col-3"  style="text-align: end;max-width: unset">
                        {{(item.unitPrice*item.qty > 0 )?formatPrice(roundToXDigits(item.unitPriceWithComm*item.qty,2)):""}}
                      </v-col>
                    </div>
                  </div>
                  <div v-if="item.itemType.name==='MINIMUM_REMAINING'">
                    <v-divider></v-divider>
                    <div  class="row" style="margin: 0px" >
                      <v-col>Minimum order remaining</v-col>
                      <v-col v-if="$router.currentRoute.path.includes('/dashboard')" class="col-3" align-self="center" style="text-align: end;max-width: unset;">
                        {{formatPrice(roundToXDigits(item.unitPrice,2))}}
                      </v-col>
                      <v-col class="col-3" align-self="center" style="text-align: end;max-width: unset">
                        {{formatPrice(roundToXDigits(item.unitPriceWithComm,2))}}
                      </v-col>
                    </div>
                    <div  class="row" style="margin: 0px" >
                      <v-col align="center" class="caption" >
                        <div>The chef requires a minimum order amount of {{formatPrice(getPriceWithCommission(booking.serviceSerialized.minPrice))}}</div>
                      </v-col>
                    </div>
                  </div>
                </div>

                <v-divider v-if="errorDishes"></v-divider>
                <div v-if="errorDishes" class="row" style="margin: 0px" >
                  <v-col align="center" class="error--text" >
                    <div>Select a Starter or a Desert</div>
                  </v-col>
                </div>
              </div>
              <v-divider></v-divider>
              <div  class="row" style="margin: 0px;font-size: 18px">
                <v-col>Total</v-col>
                <v-col v-if="$router.currentRoute.path.includes('/dashboard')" class="col-3" align-self="center" style="text-align: end;max-width: unset;">
                  {{formatPrice(total().total)}}
                </v-col>
                <v-col class="col-3" align-self="center" style="text-align: end;max-width: unset" id="booking_total">
                  {{formatPrice(total().totalRetail)}}
                </v-col>
              </div>
              <v-divider></v-divider>
              <div  class="row" >
                <v-col align="center">
                  <v-btn v-if="isAdmin || isChef" outlined color="primary" class="margin-default" :disabled="!valid"  @click="bookingItemsDialog=true">Edit</v-btn>
                  <div class="error--text " v-if="!valid">some errors are present</div>
                  <div class="margin-default" >
                      <a v-if="booking && $router.currentRoute.path.includes('/dashboard') && (booking.status.name==='CONFIRMED' || booking.status.name==='AWAITING_PAYMENT')"
                     class="primary--text caption"
                      id="btn_reject_chef" @click="rejectDialog=true;rejectMode='chef';rejectAction=cancelBooking" :loading="loading">Cancel</a>
                  </div>
                  <div class="margin-default" >
                    <a v-if="booking && (booking.status.name==='CONFIRMED' || booking.status.name==='REQUESTED' || booking.status.name==='DATE_REQUESTED' || booking.status.name==='AWAITING_PAYMENT') && $router.currentRoute.path.includes('/account') "
                       class="primary--text caption"
                       id="btn_reject_client" @click="rejectDialog=true;rejectMode='client';rejectAction=cancelBooking" :loading="loading">Cancel</a>
                  </div>
                  <!-- Check payment capturable instead of unpaid-->
                  <div v-if="$router.currentRoute.path.includes('/account') && booking.status.name==='AWAITING_PAYMENT' && booking.balance!=booking.totalWithCommission " class="row" style="margin: 0px">
                    <v-col align="center">
                      <div class="caption">
                        By clicking “Confirm and Pay” below,
                        you agree to our <a @click.stop href="/terms" target="_blank">Terms and Conditions</a> and our <a @click.stop href="/privacy" target="_blank">Privacy Policy</a>.
                      </div>
                      <stripe-element-card :pk="stripeKey"
                                           ref="elementRef"
                                           @token="handlePayment"
                                           class="margin-default">
                      </stripe-element-card>
                      <div v-if="env!=='prod'">
                        4242 4242 4242 4242 12/34 123 94107 //success
                        4000 0025 0000 3155 12/34 123 94107 //authorisationrequired
                        4000 0000 0000 9995 12/34 123 94107 //fail
                      </div>
                      <v-btn id="btn_pay" outlined color="primary" class="margin-default" :disabled="!valid || chef.profile.status.name !== 'APPROVED'"  @click="startPaymentProcess()" :loading="isLoadingPayment">Confirm And Pay</v-btn>
                      <div class="error--text " v-if="error">{{ error }}</div>
                      <div class="error--text " v-if="chef.profile.status.name !== 'APPROVED'">This provider is not available for bookings</div>
                    </v-col>
                  </div>
                </v-col>
              </div>
            </v-card>
          </div>
        </v-row>
        <popup-booking-items v-if="bookingItemsDialog" :bookingItemsDialog="bookingItemsDialog" :booking="JSON.parse(JSON.stringify(this.booking))" :close-action="closeBookingItems" :edit-action="modifyBookingItems"></popup-booking-items>
      </v-container>
      </div>
    </v-container>
</template>

<script>
    import Vue from "vue";
    import { StripeElementCard } from '@vue-stripe/vue-stripe';
    import LoadScript from 'vue-plugin-load-script'

    Vue.use(LoadScript)
    import { StripePlugin } from '@vue-stripe/vue-stripe';
    console.log(window.location.origin);

    let pk=process.env.VUE_APP_STRIPE_API_KEY;
    const options = {
      //pk: "pk_test_51J8OdNAGb7MTLpmkowUVyVsfWEUSx6R98IGrcoqG6QnKOuPGBozH0RECZGhDess3WLvOIkJrD6NaIrzpDNEeOf0Q00vuPGeSiG",
      pk: pk,
      // stripeAccount: process.env.STRIPE_ACCOUNT,
      // apiVersion: process.env.API_VERSION,
      // locale: process.env.LOCALE,
    };

    new Promise((resolve) => {
      let script = document.createElement('script')
      script.onload = () => {
        resolve();
      }
      // script.async = true;
      script.defer = true;
      script.src = 'https://js.stripe.com/v3';
      document.head.appendChild(script);
    }).then(()=>{
      Vue.use(StripePlugin, options);
    })
    import Constants from "@/constants";
    import PopupBookingItems from './PopupBookingItems';

    export default {
      name: "Bookings",
      components: { StripeElementCard,PopupBookingItems},
        data() {
            let headers= [
                {
                    text: 'Status',
                    align: 'start',
                    sortable: true,
                    value: 'status.displayName',
                },
                { text: 'reference', value: 'reference', sortable: false },
                { text: 'Name', value: 'clientName', sortable: true },
                { text: 'email', value: 'clientEmail', sortable: true },
                { text: 'chef', value: 'chefName', sortable: true },
                { text: 'date', value: 'date', sortable: true },
                { text: 'total', value: 'totalWithCommission', sortable: true,  align: 'end' },
                { text: 'balance', value: 'balance', sortable: true, align: 'end' },
                { text: 'Actions', value: 'actions', sortable: false, align: 'end' },
                // { text: '', value: 'data-table-expand'},
            ]
            return {
              stripeKey:process.env.VUE_APP_STRIPE_API_KEY,
              env: process.env.VUE_APP_ENV,
              error:undefined,
              isLoadingPayment:false,
              status:undefined,
              rejectDialog:false,
              rejectMode:undefined,
              reason:undefined,
              validReject:undefined,
              timer: null,
              totalTime: 1 * 60,
              expire:undefined,
              expireTimeLeft:undefined,
              selected:[],
              bookingItemsDialog:false,
              showNbKid:false,
              minChef:undefined,
              outOfRange:false,
              // total:0,
              waiterPrice:undefined,
              loadingAddress:false,
              locationItems:undefined,
              lookupAddress:undefined,
              distanceMessage:undefined,
              loading:false,
              loadingModify:false,
              updateSize:10,
              updatePage:undefined,
              options: {sortBy:["date"],sortDesc:["true"]},
              expanded:[],
              headers,
              search:"",
              booking:undefined,
              chef:undefined,
              valid:true,
              isLoadingBooking:false
            };
        },
        created() {
        this.log("bookings page created")
          if (this.$store.state.loggedin===false){
            return this.$router.push({path: `/`})
          }
          if (this.$root.bookingStatus!=undefined){
            this.status=this.$root.bookingStatus;
            this.$root.bookingStatus=undefined;
          }
          this.$store.state.bookingsAdmin=undefined;
            //this.searchBookings();
            // this.loading = true;
            //   // this.$store.dispatch('asyncGetBookingsAdmin',{pageNumber:0,size:this.updateSize});
            // this.$store.dispatch('asyncGetBookingsAdmin',{pageNumber:0,size:this.updateSize,sortBy:this.options.sortBy.join(),sortDesc:this.options.sortDesc.join()}).finally(()=> {
            //       this.loading = false;
            //     }
            // );

        },
      beforeDestroy() {
        delete this.$root.bookingToLoad;
      },
      mounted(){
          if (this.$store.state.loggedin===false){
            return this.$router.push({path: `./`})
          }

          if (this.$router.currentRoute.path.includes('/account')){
            this.headers=[
              {
                text: 'Status',
                align: 'start',
                sortable: true,
                value: 'status.displayName',
              },
              { text: 'reference', value: 'reference', sortable: false },
              { text: 'chef', value: 'chefName', sortable: true },
              { text: 'date', value: 'date', sortable: true },
              { text: 'total', value: 'totalWithCommission', sortable: true },
              { text: 'Actions', value: 'actions', sortable: false,align: 'end' },
              // { text: '', value: 'data-table-expand'},
            ];
          }else if (this.$router.currentRoute.path.includes('/dashboard')){
            this.headers=[
              {
                text: 'Status',
                align: 'start',
                sortable: true,
                value: 'status.displayName',
              },
              { text: 'reference', value: 'reference', sortable: false },
              { text: 'Name', value: 'clientName', sortable: true },
              { text: 'date', value: 'date', sortable: true },
              { text: 'Gross', value: 'totalWithCommission', sortable: true,align: 'end' },
              { text: 'Net', value: 'total', sortable: true,align: 'end' },
              { text: 'Actions', value: 'actions', sortable: false,align: 'end' },
              // { text: '', value: 'data-table-expand'},
            ];
          }
          let reference = this.$root.bookingToLoad;
          if (reference !== undefined) {
            this.openBooking({reference:reference});
          }
        },
        destroyed(){
        },
        computed: {
          hours: function() {
            const hours = Math.floor(this.expireTimeLeft /60 /60 );
            return this.padTime(hours);
          },
          minutes: function() {
            const minutes = Math.floor(this.expireTimeLeft/60 - this.hours * 60);
            return this.padTime(minutes);
          },
          seconds: function() {
            const seconds = this.expireTimeLeft - this.minutes * 60 - this.hours * 60 *60 ;
            return this.padTime(seconds);
          },
          itemCategories(){
            let result=new Set();
            for (var i=0; i<this.booking.items.length>0; i++) {
              var item= this.booking.items[i]
              if (!i.cancelled && item.item && item.item.itemCategory  ){
                result.add(item.item.itemCategory);
              }
            }
            return Array.from(result);
          },
          filteredItems(){
            let result;
            result= this.booking.items.filter(i=> !i.cancelled).sort(function(a, b){return a.itemType.order-b.itemType.order}).sort(function(a,b){
              if (a.itemType.name==="DISH" && b.itemType.name==="DISH" && a.item.dishType!==undefined && b.item.dishType!==undefined ){
                return a.item.dishType.order-b.item.dishType.order
              }else if (a.itemType.name==="DISH" && b.itemType.name==="DISH" && a.item.itemCategory!==undefined && b.item.itemCategory!==undefined ){
                  return a.item.itemCategory.ordering-b.item.itemCategory.ordering
                }else{
                return 0
              }});
            return result;
          },
          equipmentsService() {
            return this.$store.state.equipmentService;
          },
          errorDishes(){
            //TODO
            // if (this.$root.typeServiceSelected=="classic"){
            //   if (this.data.starter==null && this.data.desert==null){
            //     return true;
            //
            //   }else {return false;}
            // }else{
            //   return false;
            // }
            return false;
          },
          totalItems(){
            if (this.$store.state.bookingsAdmin!==undefined){
              return this.$store.state.bookingsAdmin.totalElements;
            }else{
              return undefined
            }
          },
          size(){
            if (this.$store.state.bookingsAdmin!==undefined){
              return this.$store.state.bookingsAdmin.size;
            }else{
              return undefined
            }
          },
          bookings() {
              if (this.$store.state.bookingsAdmin!==undefined){
                return this.$store.state.bookingsAdmin.content;
              }else{
                return undefined
              }
          },
          availableDates(){
            let availableDates={};
            if (this.chef!==undefined){
              if (this.chef.events!=undefined){
                this.chef.events.forEach(e => {
                  if (e.status.name==="AVAILABLE"){
                    availableDates[e.date]=true;
                  }
                })
              }
            }
            return Object.keys(availableDates);
          },
          availableTimes(){
            let times=[];
            if (this.isLunchAvailable){
              times.push.apply(times,
                  [
                    '11:00',
                    '11:30',
                    '12:00',
                    '12:30',
                    '13:00',
                    '13:30',
                    '14:00',
                    '14:30',
                    '15:00',
                    '15:30',
                    '16:00',
                    '16:30',
                  ]
              );
            }
            if (this.isDinnerAvailable){
              times.push.apply(times,
                  [
                    '17:00',
                    '17:30',
                    '18:00',
                    '18:30',
                    '19:00',
                    '19:30',
                    '20:00',
                    '20:30',
                    '21:00',
                    '21:30',
                    '22:00',
                    '22:30',
                    '23:00',
                    '23:30',
                  ]
              );
            }
            return times;
          },
          isLunchAvailable(){
            let available=false;
            let dateSelected=this.$root.formData.date;
            if (this.chef!==undefined ){
              if (this.chef.events!=undefined){
                this.chef.events.forEach(e => {
                  if (e.status.name==="AVAILABLE" && e.eventTime.name==="LUNCH"){
                    if (dateSelected!==undefined && e.date===dateSelected){
                      available = true;
                    } else if (dateSelected === undefined || dateSelected == null) {
                      available = true;
                    }
                  }
                })
              }
            }
            return available;
          },
          isDinnerAvailable(){
            let dateSelected=this.$root.formData.date;
            let available=false;

            if (this.chef!==undefined) {
              if (this.chef.events!=undefined){
                this.chef.events.forEach(e => {
                  if (e.status.name === "AVAILABLE" && e.eventTime.name === "DINNER") {
                    if (dateSelected !== undefined && e.date === dateSelected) {
                      available = true;
                    } else if (dateSelected === undefined || dateSelected == null) {
                      available = true;
                    }
                  }
                })
              }
            }
            return available;
          },
          equipmentsKitchen() {
            return this.$store.state.equipmentKitchen;
          },
          hobType() {
            return this.$store.state.hobType;
          },
          getServiceCutlery(){
            let equipments={equipmentsService:{},equipmentsKitchen:[],description:""};
            let that=this;
            this.booking.items.forEach(i=> {
              if (!i.cancelled &&i.itemType.name==="DISH"){
                for (const equipmentIndex in that.$store.state.equipmentService) {
                  let equipment = that.$store.state.equipmentService[equipmentIndex];
                  if (i.item.equipments !== undefined && i.item.equipments.filter(e => e !== undefined && e.name === equipment.name).length > 0) {
                    if (equipments.equipmentsService[equipment.name] === undefined) {
                      equipments.equipmentsService[equipment.name] = 0;
                    }
                    equipments.equipmentsService[equipment.name] += i.qty;
                  }
                }

                // Kitchen Equipment
                for (const equipmentIndex in that.$store.state.equipmentKitchen) {
                  let equipment = that.$store.state.equipmentKitchen[equipmentIndex];
                  if (i.item.equipments!==undefined && i.item.equipments.filter(e => e!==undefined && e.name === equipment.name).length>0) {
                    if (equipments.equipmentsKitchen[equipment.name]===undefined){
                      equipments.equipmentsKitchen[equipment.name]=equipment;
                    }
                  }
                }
              }
            })

            let fridge=equipments.equipmentsKitchen["FRIDGE"]!==undefined;
            let freezer=equipments.equipmentsKitchen["FREEZER"]!==undefined;
            let gaz=(this.booking.address.addressDetails.cooktopType!==undefined && this.booking.address.addressDetails.cooktopType.name==="GAZ");
            let induction=(this.booking.address.addressDetails.cooktopType!==undefined && this.booking.address.addressDetails.cooktopType.name==="INDUCTION");
            if (fridge || freezer || gaz){
              equipments.description+="Please make sure ";
              if (fridge || freezer){
                equipments.description+=" you have some free space in ";
                if (fridge){
                  equipments.description+="the fridge";
                }
                if (fridge && freezer){
                  equipments.description+=" and in ";
                }
                if (freezer){
                  equipments.description+="the freezer";
                }
              }
              if (gaz){
                if (fridge && freezer){
                  equipments.description+=" and";
                }
                equipments.description+=" you won't run out of gaz ";
              }
            }
            if (induction && !this.chef.profile.induction){
              equipments.warning="The chef is not equiped to work with induction, please verify with the chef you have all the equipment he needs";
            }
            console.log(equipments);
            return equipments
          },
        },
        methods:{
          startPaymentProcess(){
            this.$refs.elementRef.submit();
          },
          handlePayment (token) {
            let booking = this.booking;
            console.log(token);
            let stripe=this.$stripe;
            console.log(stripe);
            this.isLoadingPayment = true;
            let that=this;
            this.$store.dispatch('payment',{reference:booking.reference}).then(function(result){
              console.log(result.result);
              stripe.confirmCardPayment(result.result,{
                payment_method: {
                  card: {
                    token: token.id,
                  },
                },
              }).then(function(result) {
                if (result.error) {
                  // Show error to your customer
                  console.log(result.error.message)
                  that.$store.dispatch('showSnackbar',{text: Constants.PAYMENT_FAILED + ": "+result.error.message, color: "error"});
                  that.isLoadingPayment=false;
                  // show a fix error message to let the user pay again

                  // delete booking or just change the status to payment failed
                  // call check payment instead of waiting for the webhook
                  that.$store.dispatch('checkPayment',{paymentId:result.error.payment_intent.id}).finally(()=>{
                  });
                } else {
                  // The payment succeeded!
                  that.$store.dispatch('showSnackbar', {
                    text: Constants.PAYMENT,
                    color: "primary"
                  })
                  // call check payment instead of waiting for the webhook
                  that.$store.dispatch('checkPayment',{paymentId:result.paymentIntent.id}).finally(()=>{
                    that.isLoadingPayment=false;
                    that.openBooking(that.booking);
                  });
                }

              }).finally(()=> {
                //that.isLoadingPayment=false;
              });
            }).catch((error)=> {
              console.log(error);
              that.$store.dispatch('showSnackbar',{text: Constants.PAYMENT_FAILED + ": "+error, color: "error"});
              that.isLoadingPayment=false;
            });
          },
          getCategory(item){
            if (item.item && item.item.itemCategory){
              return "("+item.item.itemCategory.name+")";
            }else if (item.item && item.item.dishType){
              //old bookings
              return "("+item.item.dishType.displayName+")";
            }
          },
          validateReject () {
            if (this.$refs.formReject!==undefined && this.$refs.formReject!==undefined){
              return this.$refs.formReject.validate();
            }
            return true;
          },
          acceptBooking(){
            this.loading = true;
           // let that=this;
            this.$store.dispatch('acceptBooking',this.booking.reference).then((response)=>{
              if (response !== undefined && response.status === 200) {
                this.$store.dispatch('showSnackbar', {
                  text: Constants.CONFIRMED,
                  color: "primary"
                })
                console.log("accept");
                console.log(response);
                // console.log(that.$parent.user);
                // that.$parent.user=response.data.result.chef;
                //this.chef=response.result.chef;
                this.searchBookings()
                this.openBooking(this.booking)
              }
            })
            .finally(()=> {
              this.loading = false;
            }
            );
          },
          rejectBooking(){
            this.rejectDialog=false;
            console.log("reject");
            this.loading = true;
            //let that=this;
            this.$store.dispatch('rejectBooking',{reference:this.booking.reference,reason:this.reason,mode:this.rejectMode}).then((response)=>{
              if (response !== undefined && response.status === 200) {
                this.$store.dispatch('showSnackbar', {
                  text: Constants.CANCELLED,
                  color: "primary"
                })
                console.log("reject");
                console.log(response);
                // console.log(that.$parent.user);
                // that.$parent.user=response.data.result.chef;
                //this.chef=response.result.chef;
                this.searchBookings()
                this.openBooking(this.booking)
              }
            })
                .finally(()=> {
                      this.loading = false;
                    }
                );
          },
          cancelBooking(){
            this.rejectDialog=false;
            console.log("cancel");
            this.loading = true;
            //let that=this;
              this.$store.dispatch('cancelBooking',{reference:this.booking.reference,reason:this.reason,mode:this.rejectMode}).then((response)=>{
                if (response !== undefined && response.status === 200) {
                  this.$store.dispatch('showSnackbar', {
                    text: Constants.CANCELLED,
                    color: "primary"
                  })
                  console.log("reject");
                  console.log(response);
                  // console.log(that.$parent.user);
                  // that.$parent.user=response.data.result.chef;
                  //this.chef=response.result.chef;
                  this.searchBookings()
                  this.openBooking(this.booking)
                }
              })
                  .finally(()=> {
                        this.loading = false;
                      }
                  );
          },
          getColor (status) {
            if (status.name ==='REQUESTED') return '#bfe9fc'
            else if (status.name ==='DATE_REQUESTED') return '#e6d6ff'
            else if (status.name ==='AWAITING_PAYMENT') return '#96e8e2'
            else if (status.name ==='CART') return '#fff4b3'
            else if (status.name ==='CONFIRMED' || status.name ==='COMPLETED' || status.name ==='PAYOUT' || status.name ==='DONE') return '#b3efcb'
            else if (status.name ==='CANCELLED_CLIENT' || status.name ==='CANCELLED_CHEF' || status.name ==='REJECTED') return '#fcc7c3'
            else return ''
          },
          modifyBookingItems(booking){
            this.booking=booking;
            this.bookingItemsDialog=false;
          },
          closeBookingItems(){
            this.bookingItemsDialog=false;
          },
          modify(){
            this.loadingModify=true;
            this.$store.dispatch('modifyBooking', this.booking )
                .then((response) => {
                  if (response !== undefined && response.statusCode === 200) {
                    this.$store.dispatch('showSnackbar', {
                      text: Constants.SAVED,
                      color: "primary"
                    })
                    this.searchBookings()
                    this.openBooking(this.booking)
                  }
                }).finally(()=>{this.loadingModify=false;});
          },
          checkChip(tags,tag) {
            let found = false;
            if (tags!==undefined){
              found=tags.filter(t => t.name === tag.name).length > 0 ? true : false;
            }else{
              found=false
            }
            // return tags !== undefined && tags.filter(t => t.name === tag.name).length > 0 ? true : false;
            return found;
          },
          checkRange(){
            let result=[false,0];

            if (this.chef!=undefined && this.chef.locations!==undefined && this.booking.address.addressDetails.geolocation){
              for (let i = 0; i < this.chef.locations.length; i++) {
                let location=this.chef.locations[i].location.coordinates;
                let distance = this.getDistanceFromLatLonInKm(location[1],location[0],this.booking.address.addressDetails.geolocation[1],this.booking.address.addressDetails.geolocation[0]);
                if (!result[0] && distance<=this.chef.locations[i].maxRange){
                  result=[true,0];
                  break;
                }else{
                  result=[false,(Math.round((distance-this.chef.locations[i].maxRange)*10)/10)];
                }
              }
            }
            return result;
          },
          checkDistance(){
            let chef;
            if (this.$parent.user!==undefined && this.$parent.user.id === this.chef.profile.userId){
              chef=this.$parent.user;
            }
            if ( this.$store.state.user.id === this.chef.profile.userId){
              chef=this.$store.state.user;
            }
            if (chef!==undefined){
              let result=0;
              if (chef.userDetails!==undefined && chef.userDetails.location!==undefined ){
                let location=chef.userDetails.location.coordinates;
                let distance = this.getDistanceFromLatLonInKm(location[1],location[0],this.booking.address.geolocation.coordinates[1],this.booking.address.geolocation.coordinates[0]);
                result=(Math.round((distance)*10)/10);
              }
              this.distanceMessage="Distance form your address "+result + " Km";
            }
            return this.distanceMessage;
          },
          total(){
            let result={total:0,totalRetail:0}
            this.booking.items.filter(i=> !i.cancelled).forEach(i=>{
              result.total+=i.unitPrice*i.qty;
              result.totalRetail+=i.unitPriceWithComm*i.qty;
            });
            result.total=this.roundToXDigits(result.total,2);
            result.totalRetail=this.roundToXDigits(result.totalRetail,2);
            return result;
          },
          validateEquipmentsKitchen(){
            let equipmentsKitchen=this.getServiceCutlery.equipmentsKitchen;
            let message="Your dishes selection requires: ";
            let missing =false;
            for (const i in equipmentsKitchen){
              let equipment= equipmentsKitchen[i];
              if (this.booking.address.addressDetails.equipments.find(e => e.name===equipment.name)===undefined){
                if (missing){
                  message+=", ";
                }
                message+=equipment.displayName;
                missing=true;
              }
            }
            if (missing){
              return message;
            }
            return undefined;
          },
          getLocation(val){
            console.log(val);
            console.log(this.booking.location);
            if (this.booking.location!==undefined){
              this.booking.address.geolocation=val.geometry;
            }else{
              this.booking.address.geolocation=undefined;
            }
            this.distanceMessage=this.checkDistance();
            return this.booking.address.geolocation;
          },
          selectBooking(val){
            // load full booking
            this.booking=val;
          },
          searchBookings(){
            let status=this.status!=undefined?this.status.name:"";
            this.loading=true;
            this.$store.dispatch('asyncGetBookingsAdmin',{pageNumber:0,size:this.updateSize,sortBy:this.options.sortBy.join(),sortDesc:this.options.sortDesc.join(),search:this.search,id:this.restrictId(),bookingStatus:status}).finally(()=> {
                  this.loading = false;
                }
            );
          },
          handleClickRow(row) {
            console.log(row);
          },
          startTimer: function() {
            this.timer = setInterval(() => this.countdown(), 1000);
          },

          padTime: function(time) {
            return (time < 10 ? "0" : "") + time;
          },
          countdown: function() {
            if (this.expireTimeLeft >= 1) {
              this.expireTimeLeft--;
            } else {
              this.expireTimeLeft = 0;
            }
          },
          deleteBooking(item) {
            this.$store.dispatch('asyncDeleteBooking',{reference:item.reference});

          },
          openBooking(item){
            this.selected=[item];
            let that=this;
            this.isLoadingBooking=true;
            this.$store.dispatch('asyncGetBookingAdmin',{reference:item.reference}).then((val)=> {
              this.booking = val.result.booking;
              this.chef = val.result.chef;
              if ( this.booking.status.name==="REQUESTED" || this.booking.status.name==="DATE_REQUESTED" ){
                let requested=this.booking.history.find(h=>h.status.name==="REQUESTED" || h.status.name==="DATE_REQUESTED" );
                if (requested!==undefined){
                  let rejectDelay=this.$store.state.parameters.find(p=>p.name.name==="REJECT_DELAY");
                  this.expire=new Date(Date.parse(requested.creationDate)+1*parseInt(rejectDelay.value)*60*60*1000);
                  this.expireTimeLeft=Math.floor((new Date(requested.creationDate+parseInt(rejectDelay.value)*60*60*1000) - new Date().getTime() )/1000);
                  this.startTimer();
                }
              }

              this.booking.location = {place_name: this.booking.clientAddress};
              this.locationItems = [{place_name: this.booking.clientAddress}];
              this.chef = val.result.chef;
              // this.booking=item;
              this.booking.serviceSelected = val.result.booking.serviceSerialized;
              this.booking.dishesSelected = {};
              that.showNbKid = false;
              this.booking.items.forEach(bi => {
                if (bi.itemType.name === "DISH" && !bi.cancelled) {
                  if (bi.item.itemCategory!==undefined){
                    if (that.booking.dishesSelected[bi.item.itemCategory.name] === undefined) {
                      that.booking.dishesSelected[bi.item.itemCategory.name] = [];
                    }
                    if (bi.item.itemCategory.categoryType.name === "KID") {
                      that.showNbKid = true;
                    }
                    that.booking.dishesSelected[bi.item.itemCategory.name].push(bi.item);
                  }else{
                    if (bi.item.dishType){
                      // for compatibilite with the old structure we can remove it when fully migrated
                      if ( that.booking.dishesSelected[bi.item.dishType.name] === undefined) {
                        that.booking.dishesSelected[bi.item.dishType.name] = [];
                      }
                      if ( bi.item.dishType.name === "KID") {
                        that.showNbKid = true;
                      }
                      that.booking.dishesSelected[bi.item.dishType.name].push(bi.item);
                    }

                  }

                }
              });

              let equipments = [];
              if (this.booking.address.addressDetails.equipments !== undefined) {
                this.equipmentsKitchen.forEach(dt => {
                  this.booking.address.addressDetails.equipments.forEach(nddt => {
                    if (nddt.name === dt.name) {
                      equipments.push(dt);
                    }
                  })
                })
              }
              this.booking.address.addressDetails.equipments = equipments;
              this.checkDistance();
              this.$nextTick(() => this.$vuetify.goTo(".booking"))
              }
            ).finally(()=>{
              this.isLoadingBooking = false;
            });
          },
          restrictId(){
            let restrictedId
            if (this.$route.params.id!==undefined){
              restrictedId=this.$route.params.id
            }else if (!this.$router.currentRoute.path.includes('/admin') && this.$store.state.loggedin){
              restrictedId=this.$store.state.user.id;
            }
            return restrictedId;
          },
        },
        watch:{
          options(val){
            let status=this.status!=undefined?this.status.name:"";
            this.loading = true;
            this.$store.dispatch('asyncGetBookingsAdmin',{pageNumber:val.page-1,size:val.itemsPerPage,sortBy:val.sortBy.join(),sortDesc:val.sortDesc.join(),search:this.search,id:this.restrictId(),bookingStatus:status}).finally(()=> {
                  this.loading = false;
                }
            );
          },
          lookupAddress (val) {
            if (val===undefined || val===null || val.length<4) {
              return
            }
            // Items have already been loaded
            // if (this.items !==undefined && this.items.length > 0) return

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            let that=this;

            // using mapbox
            this.$store.dispatch('lookupAddressMapbox',val).
            then(response => {
              that.locationItems=response; })
                .finally(() => (this.isLoading = false));
          },
        }
    }
</script>

<style scoped>

</style>